import { graphql } from 'gatsby'
import React from 'react'

import Article from '../screens/article'

const CompanyPost = (props) => {
  const article = props.data.wpCompanySingleNews

  return <Article {...props} article={article} category="company-news" />
}

export default CompanyPost

export const pageQuery = graphql`
  query PostById($slug: String!, $lang: String) {
    wpCompanySingleNews(
      locale: { locale: { eq: $lang } }
      slug: { eq: $slug }
    ) {
      id
      title
      content
      date(formatString: "MMM DD, YYYY")
      dateGmt
      slug
      status
      excerpt
      nodeType
      tags {
        nodes {
          name
        }
      }
      categories {
        nodes {
          name
          slug
          description
          id
        }
      }
      acf: acfCompanyNews {
        articleExcerpt
        newTemplateStyle
        introduction
        mediaType
        coverMediaSource {
          mediaItemUrl
          description
          altText
          caption
          acfMediaType {
            mediaDeviceType
          }
        }
      }
      featuredImage {
        node {
          caption
          sourceUrl
        }
      }
    }
  }
`
